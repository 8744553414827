import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/cryptowander/Maker/test/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`General MIP Template`}</h1>
    <h2>{`Preamble`}</h2>
    <pre><code parentName="pre" {...{}}>{`MIP#:
Title:
Author(s): 
Contributors:
Type: 
Status: <Assigned by MIP Editor>
Date Proposed: <yyyy-mm-dd>
Date Ratified: <yyyy-mm-dd>
Dependencies:
Replaces:
`}</code></pre>
    <h2>{`References`}</h2>
    <ul>
      <li parentName="ul">{`A list of supporting materials referenced by this MIP.`}</li>
    </ul>
    <h2>{`Sentence Summary`}</h2>
    <ul>
      <li parentName="ul">{`A description of what the Maker Improvement Proposal (MIP) is focused on. Suggest 30 words max.`}</li>
    </ul>
    <h2>{`Paragraph Summary`}</h2>
    <ul>
      <li parentName="ul">{`A description of what the Maker Improvement Proposal (MIP) is focused on. Suggest 100 words max.`}</li>
    </ul>
    <h2>{`Component Summary`}</h2>
    <ul>
      <li parentName="ul">{`A description of the purpose of each component in the MIP . Suggest 30 words max per component.`}</li>
    </ul>
    <h2>{`Motivation`}</h2>
    <ul>
      <li parentName="ul">{`A short description of the motivation behind the MIP. `}</li>
    </ul>
    <h2>{`Specification / Proposal Details`}</h2>
    <ul>
      <li parentName="ul">{`Proposed process standard details - describes the new process or feature and the problem it is solving.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      